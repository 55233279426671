label#search-by-select-label, label#phone-country-code-label {
    top: 6px;
    font-size: 13px;
}

button#track-btn {
    margin-top: 5px;
    font-family: 'Montserrat Variable', sans-serif;
}

button#track-btn:disabled {
    opacity: 0.8;
}

#not-found-text {
    margin: 12px 0;
    text-align: center;
}

button#not-found-btn {
    font-family: 'Montserrat Variable', sans-serif;
}

button#search-again-btn {
    font-family: 'Montserrat Variable', sans-serif;
    width: -webkit-fill-available;
    margin: 22px 20px;
}


ul.result-list {
    display: flex;
    flex-direction: row;
    padding: 0;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap; /* Ensure content stays on one line */
}

ul.result-list > .result-list-item {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    display: flex;
    margin: 0 2px;
    min-width: 100px;
    width: -webkit-fill-available;
    min-height: min-content;
}

/* Chrome, Edge and Safari */
ul.result-list::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

ul.result-list::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #DFE9EB;
}

ul.result-list::-webkit-scrollbar-track:hover {
    background-color: #DFE9EB;
}

ul.result-list::-webkit-scrollbar-track:active {
    background-color: #DFE9EB;
}

ul.result-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #8a8a8a;
}

ul.result-list::-webkit-scrollbar-thumb:hover {
    background-color: #808080;
}

ul.result-list::-webkit-scrollbar-thumb:active {
    background-color: #8a8a8a;
}